import { z } from "zod";

export const pricePublicSchema = z.object({
  id: z.string(),
  name: z.string(),
  cents: z.number(),
});
export type PricePublic = z.infer<typeof pricePublicSchema>;

export const retreatPublicSchema = z.object({
  title: z.string(),
  slug: z.string(),
  description: z.string(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
  prices: z.array(pricePublicSchema).nonempty(),
});
export type RetreatPublic = z.infer<typeof retreatPublicSchema>;
export const retreatsPublicSchema = z.array(retreatPublicSchema);

export const retreatPublicBasicSchema = z.object({
  title: z.string(),
  slug: z.string(),
  start_date: z.coerce.date(),
  end_date: z.coerce.date(),
});
export type RetreatPublicBasic = z.infer<typeof retreatPublicBasicSchema>;
export const retreatsPublicBasicSchema = z.array(retreatPublicBasicSchema);

const priceAdminSchema = pricePublicSchema.extend({
  archived: z.boolean(),
});
export const retreatAdminSchema = retreatPublicSchema.extend({
  id: z.string(),
  participant_info: z.string(),
  zoom_meeting_id: z.string(),
  booking_open: z.boolean(),
  published: z.boolean(),
  prices: z.array(priceAdminSchema).nonempty(),
});
export const retreatsAdminSchema = z.array(retreatAdminSchema);
export type RetreatsAdmin = z.infer<typeof retreatsAdminSchema>;

export const priceWriteSchema = z.object({
  name: z.string().min(1),
  cents: z
    .preprocess((v, ctx) => {
      if (typeof v === "string") {
        return Number(v) * 100;
      } else if (typeof v === "number") {
        return v;
      } else {
        ctx.addIssue({
          code: z.ZodIssueCode.invalid_type,
          message: "Expected: string or number",
          expected: "number",
          received: typeof v,
        });
      }
    }, z.number())
    .pipe(z.number().min(0)),
});
const buildRetreatWriteSchema = (ext = {}) =>
  z
    .object({
      title: z.string().min(1),
      slug: z
        .string()
        .min(1)
        .regex(
          /^[a-z0-9-]+$/,
          "Can only contain lowercase letters, numbers and -",
        ),
      description: z.coerce.string(),
      participant_info: z.coerce.string(),
      start_date: z.coerce.date(),
      end_date: z.coerce.date(),
      zoom_meeting_id: z
        .string()
        .min(1)
        .transform((val, ctx) => {
          const cleaned = val.replace(/\s/g, "");
          if (cleaned.search(/\D/) !== -1) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: "Can only contain numbers",
            });
          }
          return cleaned;
        }),
      booking_open: z.boolean(),
      published: z.boolean(),
      prices: z.array(priceWriteSchema).nonempty(),
    })
    .extend(ext);
export const retreatWriteSchema = buildRetreatWriteSchema().refine(
  (data) => data.end_date >= data.start_date,
  {
    message: "Can't be before the start date",
    path: ["end_date"],
  },
);

export const priceFormSchema = priceWriteSchema.extend({
  cents: z.string().regex(/^\d+(\.\d\d)?$/, "Invalid amount"),
});
export const retreatFormSchema = buildRetreatWriteSchema({
  prices: z.array(priceFormSchema).nonempty(),
});

export const retreatBookedPublicSchema = retreatPublicSchema.and(
  z.object({
    participant_info: z.string(),
  }),
);
export type RetreatBooked = z.infer<typeof retreatBookedPublicSchema>;
